import { Variable } from './../typescript/types';
import { getDollarAdjustedTitle } from './../helpers/Tables';
import { DefaultRootState } from 'react-redux';

import * as Table from './Table';
import * as Survey from './Survey';
import * as Report from './Report';
import * as ReportData from './ReportData';
import { getGeosNavigationStatus } from './ReportNavigator';

import * as Sort from '../helpers/Sort';
import { intl } from '../helpers/CreateIntl';
import * as ReportHelper from '../helpers/Report';
import { constructTableForReport, getTableLabel } from '../helpers/Tables';
import BackendPaths from '../helpers/BackendPaths';
import ApplicationPaths from '../helpers/ApplicationPaths';
import { MAX_GEOGRAPHIES_ALLOWED_FOR_DOWNLOAD } from '../helpers/Geographies';

import * as ReportApp from '../typescript/types';
import { filterFalsy } from '../typescript/helper';
import { AggregationTypes } from '../typescript/enums';
import { ALL_SLS_TOTAL_COLUMN_NAME } from '../constants/Report';
import { blackListedSurveysForCSVDownload } from '../constants/BlackLists';

export const getTableByGuid = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
    guid: ReportApp.TableGuid,
) => state.reportMetadata.byOldReportId[oldReportId].tables[guid];

export const getTableGuids = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) => state.reportMetadata.byOldReportId[oldReportId].tableGuids;

export const getTables = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) =>
    getTableGuids(state, oldReportId)
        .map((guid) => getTableByGuid(state, oldReportId, guid))
        .filter(filterFalsy);

export const getIsSaved = (state: DefaultRootState) =>
    state.reportMetadata.isSaved;

export const getDefinition = (state: DefaultRootState) =>
    state.reportMetadata.definition;

export const getClientValues = (state: DefaultRootState) => {
    const clientValues: ReportApp.ClientValues = {
        getTime: async (startPoint, endPoint, profile) => {
            const coordinates = `${startPoint.lng},${startPoint.lat};${endPoint.lng},${endPoint.lat}`;

            // Mapbox set Cache-Control header to 1 hour (3600s)
            const response = await fetch(
                `https://api.mapbox.com/directions/v5/${profile}/${coordinates}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
            );
            const data = (await response.json()) as ReportApp.MapboxService.DistanceResponse;
            if (!data.routes.length) {
                return null;
            }
            // We will use the first route
            const route = data.routes[0];

            const durationInMin = route.duration / 60;
            return durationInMin;
        },
    };
    if (state.reportMetadata.definition?.center) {
        clientValues.lat = state.reportMetadata.definition.center.lat;
        clientValues.lng = state.reportMetadata.definition.center.lng;
    }
    if (state.reportMetadata.definition?.ringType) {
        clientValues.ringType = state.reportMetadata.definition.ringType;
    }
    return clientValues;
};

export const hasSingleOldReport = (state: DefaultRootState) => {
    const definition = getDefinition(state);
    return (
        definition?.geoGroups.length === 1 &&
        definition.geoGroups[0].columnGroups.length === 1 &&
        definition.geoGroups[0].columnGroups[0].tables.length === 1
    );
};

export const getOldReportIds = (state: DefaultRootState) => {
    const definition = getDefinition(state);
    if (!definition) {
        return [];
    }
    return ReportHelper.getOldReportIdsFromDefinition(definition);
};

export const getOldReportIdsBySurveyGroupId = (
    state: DefaultRootState,
    surveyGroupId: number,
) => {
    const oldReportIds = getOldReportIds(state);
    const surveyGroup = Survey.getSurveyGroupById(state, surveyGroupId);
    return oldReportIds.filter((oldReportId) => {
        const table = getTables(state, oldReportId)[0];
        const survey = Survey.getSurveyByCode(state, table.surveyCode);
        return surveyGroup.surveyCodes.includes(survey.code);
    });
};

export const getShouldFetchMetadata = (state: DefaultRootState) =>
    getIsSaved(state) == null || !getDefinition(state);

export const getTotalNumberOfGeographiesByOldReportId = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) => state.reportMetadata.byOldReportId[oldReportId]?.totalNumberOfGeographies;

export const getShouldFetchTotalNumberOfGeographies = (
    state: DefaultRootState,
) => {
    const metadata = state.reportMetadata.byOldReportId;
    const oldReportIds = Object.keys(metadata);
    if (!oldReportIds.length) {
        return true;
    }
    return oldReportIds.some(
        (oldReportId) =>
            getTotalNumberOfGeographiesByOldReportId(state, oldReportId) ==
            null,
    );
};

const getReportFipsCodes = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) => state.reportMetadata.byOldReportId[oldReportId]?.fipsCodes;

/**
 * Check if any of oldReportIds has already
 * loaded fips codes. If one has, all of them have.
 */
export const getShouldFetchReportFipsCodes = (state: DefaultRootState) => {
    const firstOldReport = getOldReportIds(state);
    return !getReportFipsCodes(state, firstOldReport[0]);
};

export const getGeoTypeSelections = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) => {
    if (!getIsDefinitionAndByOldReportIdLoaded(state)) {
        return [];
    }
    return state.reportMetadata.byOldReportId[oldReportId].geoTypeSelections;
};

const getAvailableDollarAdjustmentYearsByOldReportId = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) =>
    state.reportMetadata.byOldReportId[oldReportId]
        .availableDollarAdjustmentYears;

export const getAvailableDollarAdjustmentYears = (state: DefaultRootState) => {
    const isSingleReport = hasSingleOldReport(state);
    if (!isSingleReport) {
        return null;
    }
    const oldReportId = getSingleReportId(state);
    const availableDollarAdjustmentYears: (
        | number
        | string
    )[] = getAvailableDollarAdjustmentYearsByOldReportId(
        state,
        oldReportId,
    ).slice();
    if (availableDollarAdjustmentYears.length) {
        availableDollarAdjustmentYears.splice(
            0,
            1,
            intl.formatMessage({ id: 'none' }),
        );
        return availableDollarAdjustmentYears;
    }
    return null;
};

export const getSingleReportId = (state: DefaultRootState) =>
    Object.keys(state.reportMetadata.byOldReportId)[0];

export const getReportTitle = (state: DefaultRootState) => {
    return state.reportMetadata.reportTitle || '';
};

export const getReportDescription = (state: DefaultRootState) => {
    return state.reportMetadata.reportDescription || '';
};

/**
 * Aggregation is not allowed in case of UDA reports (reports created through tractIQ).
 * We know that a report is a UDA report if it has more than 1 geoGroup, or if it has
 * only one geoGroup which has a propery name (for example "1 mile radius")
 */
export const getShouldAllowAggregation = (state: DefaultRootState) => {
    const definition = getDefinition(state);
    const firstGeoGroupName = definition?.geoGroups?.[0]?.name;
    if (!definition || definition.geoGroups.length !== 1 || firstGeoGroupName) {
        return false;
    }
    return definition.geoGroups[0].columnGroups.some((columnGroup) =>
        columnGroup.tables.some((oldReportId) =>
            getGeoTypeSelections(state, oldReportId).some(
                (geoTypeSelection) => geoTypeSelection.selectionCount > 1,
            ),
        ),
    );
};

// new report
export const getMaxNumberOfColumnsForOldReport = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) => {
    const tables = getTables(state, oldReportId);
    const columnsLength = tables.map((table) => table.colSpan);
    return Math.max(...columnsLength);
};

/**
 * This function will get all geo columns that needs to be displayed in report.
 * It will include geographies from all geo pages.
 *
 * There is case when a certain geo item exists in one year and
 * does not exist in another. We need to make sure that all geo items are
 * included ONCE in report results.
 * If we only go linearly through all oldReportIds and accumulate their
 * geo items, it may happen that certain geo item is completely
 * skipped, or shown multiple times.
 *
 *
 * @example
 * Imagine a report with 2 survey years, 2014(R123) and 2018(R124) where those years include following geoItems:
 * R123: Perkins County(46105), Arizona(05), South Dakota(46)
 * R124: Oglala Lakota County(46102), Perkins County(46105), Arizona(05), South Dakota(46)
 *
 * So that are 4 geo items in total with following geoItems per oldReportId:
 * ARIZONA         SOUTH DAKOTA     Totals(States)      OGLALA LAKOTA COUNTY     PERKINS COUNTY     Totals(counties)
 * R123: 05        R123: 46         R123: total-040     R123: null               R123: 46105        R123: null
 * R124: 05        R124: 46         R123: total-040     R124:46102               R124: 46105        R123: total-050
 *
 * Geo page: 2
 * Items per geo page: 3
 * fipsCodesInPage: 46102, 46105
 *
 * For oldReport R123 this function will return [4, 1] (startIndex is 4, range is 1, which includes items: 46105)
 * For oldReport R124 this function will return [4, 3] (startIndex is 4, range is 3, which includes items: 46102, 46105, total-050)
 */
export const calculateGeographiesHeader = (
    state: DefaultRootState,
): ReportApp.Geography[] => {
    const definition = getDefinition(state);
    if (!definition) {
        return [];
    }

    return definition.geoGroups.flatMap(
        ({ columnGroups, aggregationType }, index) => {
            const fipsCodesOfOldReport: ReportApp.FipsCodeWithSummaryLevel[][] = [];
            let colSpan = 0;
            const sideBySide: ReportApp.SideBySideDefinition[] = [];
            for (const { tables, name } of columnGroups) {
                const {
                    groupColSpan,
                    fipsCodesWithSumLev,
                } = getGroupColSpanAndGeoFipsCodes(
                    state,
                    tables,
                    aggregationType,
                );
                colSpan += groupColSpan;
                fipsCodesOfOldReport.push(fipsCodesWithSumLev);
                sideBySide.push({
                    name,
                    colSpan: groupColSpan,
                    isDiff: false,
                });
            }
            // check if percent diff will be shown for this geo group
            if (columnGroups.length > 1) {
                const changeTitle = intl.formatMessage(
                    { id: 'changeTitle' },
                    {
                        from: columnGroups[0].name,
                        to: columnGroups[columnGroups.length - 1].name,
                    },
                );
                sideBySide.push({
                    colSpan: 1,
                    name: changeTitle,
                    isDiff: true,
                });
                colSpan += 1;
            }

            // When report contains multiple years, and some geo item is present in latter years
            // and missing in the first year for example, then it will happen that its geo fips
            // will not be placed in it's right position. Consequently, that geoItem will be shown
            // after Total column of that geo items group. To evade that, we will call the sort
            // function on geo fipses.
            const sortedFipsCodes = Sort.mergeAndMaintainRelativeOrder(
                fipsCodesOfOldReport,
            );
            return sortedFipsCodes.map((geoFips) => {
                return {
                    geoGroupIndex: index,
                    geoFips,
                    colSpan,
                    sideBySide,
                };
            });
        },
    );
};

export const getGeographies = (
    state: DefaultRootState,
): ReportApp.Geography[] => state.reportMetadata.geographiesHeader;

export const getPaginatedGeographies = (state: DefaultRootState) => {
    const [geoItemPage, geoItemsPerPage] = getGeosNavigationStatus(state);

    const lastGeoElementIndex = geoItemPage * geoItemsPerPage;
    const firstGeoElementIndex = lastGeoElementIndex - geoItemsPerPage;

    return getGeographies(state).slice(
        firstGeoElementIndex,
        lastGeoElementIndex,
    );
};

export const shouldDisplayGeoNames = (state: DefaultRootState) => {
    const definition = getDefinition(state);

    return !!definition?.geoGroups.some(
        (columnGroup) =>
            !columnGroup.name ||
            (columnGroup.aggregationType !== AggregationTypes.ONLY_TOTALS &&
                columnGroup.aggregationType !== AggregationTypes.ALL_SLS_TOTAL),
    );
};

export const getPaginatedGeographiesWithNames = (state: DefaultRootState) => {
    const slicedAndSortedGeographies = getPaginatedGeographies(state);
    return getGeographiesWithNames(state, slicedAndSortedGeographies);
};

export const getGeographyNamesForModifyGeographyButton = (
    state: DefaultRootState,
) => {
    if (shouldDisplayGeoNames(state)) {
        return getPaginatedGeographiesWithNames(state);
    }
    return getPaginatedGeoGroupNames(state);
};

export const getAllGeographiesWithNames = (state: DefaultRootState) =>
    getGeographiesWithNames(state, getGeographies(state));

const getGeographiesWithNames = (
    state: DefaultRootState,
    sortedAndSlicedGeographies: ReportApp.Geography[],
): ReportApp.GeographyWithName[] => {
    const definition = getDefinition(state);
    if (
        !getIsDefinitionAndByOldReportIdLoaded(state) ||
        !definition ||
        !sortedAndSlicedGeographies.length
    ) {
        return [];
    }

    return sortedAndSlicedGeographies.map((geography) => {
        const geographyWithName: ReportApp.GeographyWithName = {
            ...geography,
            geoName: '',
        };

        const { columnGroups } = definition.geoGroups[geography.geoGroupIndex];

        for (const { tables } of columnGroups) {
            for (const oldReportId of tables) {
                const geoQNames = ReportData.getGeoQNames(state, oldReportId);
                const geoFipsCodesWithSl = ReportData.getFipsCodesWithSummaryLevel(
                    state,
                    oldReportId,
                );

                const matchingIndex = geoFipsCodesWithSl.indexOf(
                    geography.geoFips,
                );
                const matchingGeoName = geoQNames[matchingIndex];
                geographyWithName.geoName = matchingGeoName || '';
                if (geographyWithName.geoName.length) {
                    return geographyWithName;
                }
            }
        }
        return geographyWithName;
    });
};

export const getPaginatedGeoGroupNames = (state: DefaultRootState) => {
    const slicedAndSortedGeographies = getPaginatedGeographies(state);
    return getGeoGroupNames(state, slicedAndSortedGeographies);
};

export const getAllGeoGroupNames = (state: DefaultRootState) =>
    getGeoGroupNames(state, getGeographies(state));

export const shouldDisplayGeoGroupNames = (state: DefaultRootState) => {
    const definition = getDefinition(state);

    return !!definition?.geoGroups.some((columnGroup) => !!columnGroup.name);
};

export const getGeoGroupNames = (
    state: DefaultRootState,
    sortedAndSlicedGeographies: ReportApp.Geography[],
): ReportApp.GeoGroupName[] => {
    const definition = getDefinition(state);

    if (!definition) {
        return [];
    }

    return sortedAndSlicedGeographies.reduce(
        (
            columnGroupNames: ReportApp.GeoGroupName[],
            { geoGroupIndex, colSpan },
        ) => {
            let groupGroupWithName = columnGroupNames.find(
                (geoGroup) => geoGroup.geoGroupIndex === geoGroupIndex,
            );
            if (!groupGroupWithName) {
                groupGroupWithName = {
                    geoGroupIndex,
                    geoName: definition.geoGroups[geoGroupIndex].name || '',
                    colSpan: 0,
                };
                columnGroupNames.push(groupGroupWithName);
            }
            groupGroupWithName.colSpan += colSpan;
            return columnGroupNames;
        },
        [],
    );
};

export const getShouldDisplaySideBySide = (state: DefaultRootState) => {
    const definition = getDefinition(state);
    if (!definition) {
        return false;
    }
    return definition.geoGroups.some(
        ({ columnGroups }) => columnGroups.length > 1,
    );
};

export const getTablesUsedInReport = (state: DefaultRootState) => {
    const definition = getDefinition(state);
    if (!definition) {
        return [];
    }
    const selectedDollarAdjustmentYear = Report.getSelectedDollarAdjustment(
        state,
    );
    const isCOT = isReportCOT(state);
    const tablesDefinition: ReportApp.TablesUsedInReport[] = [];
    for (const { columnGroups } of definition.geoGroups) {
        for (const { tables } of columnGroups) {
            for (const oldReportId of tables) {
                const tablesForReport = getTables(state, oldReportId);
                for (const table of tablesForReport) {
                    let tableCode: string;
                    let def: ReportApp.TablesUsedInReport | undefined;
                    let index = 0;
                    // Why do we need index (and this weird while loop) as the
                    // part of the tableCode? Because of data.
                    // To explain I'll take three examples.
                    //
                    // 1. Table name uniqueness
                    // Under one survey, each table is unique when you combine
                    // dataset abbreviation and table name
                    //
                    // 2. ACS dataset abbreviation issue
                    // Each ACS has two datasets. One is called SE, other doesn't
                    // have fixed name. It depends on the year. So, It can be
                    // ACS18_5yr or ACS13_5yr. As you can see, that is the reason
                    // why dataset abbreviation can't be part of the code.
                    // If we put dataset abbreviation as a part of tableCode,
                    // two ACSs wouldn't be paired, and tables would be rendered
                    // one below the other, not side by side.
                    //
                    // Conclusion after first two points:
                    // We can't use dataset abbreviation + table name as a code.
                    // What we really need to display table side by side is for
                    // table name and number of variables to match. And it turns
                    // out that ACS/FBI and most of the surveys have only one
                    // table with combo: tableName+variableCount
                    //
                    // But...
                    // 3. C2000
                    // Census 2000 has tables with same table name and same
                    // number of variables in different datasets. Examples:
                    // 1|P001, 19|P018, 1|H001, 7|H005, 7|H008, 3|H012, 3|H018
                    // And when you combine only those two params (number of
                    // variables and table name), we have some tables that are
                    // not unique in that regard. This results in rendering only
                    // last table in reports that have tables with same name and
                    // number of variables.
                    //
                    // How to solve this?
                    // Since we are not allowing side by side for census data,
                    // we don't need to worry that one census will not find
                    // matching table in other census. We only need to solve
                    // issue to render every table for census.
                    // That is why index is there. In most surveys (ACS, FBI),
                    // index will always be 0. Only in surveys that have tables
                    // with same name and same number of variables we will have
                    // case where index is greater than 0.
                    while (true) {
                        const code = `${table.variableCount}|${table.name}|${index}`;
                        def = tablesDefinition.find((def) => def.code === code);
                        if (!def || !def.guidByOldReportId[oldReportId]) {
                            tableCode = code;
                            break;
                        }
                        index += 1;
                    }
                    if (!def) {
                        const { datasetAbbreviation, name, surveyCode } = table;
                        def = {
                            code: tableCode,
                            title: getTableLabel(
                                table,
                                isCOT,
                                selectedDollarAdjustmentYear,
                            ),
                            url: BackendPaths.metadataLink.getPath({
                                surveyCode,
                                datasetAbbreviation,
                                name,
                            }),
                            guidByOldReportId: {},
                            showDollarAdjustmentInfo:
                                !!table.dollarYear &&
                                !!selectedDollarAdjustmentYear &&
                                table.dollarYear !==
                                    selectedDollarAdjustmentYear,
                            dollarYear: table.dollarYear,
                        };
                        tablesDefinition.push(def);
                    }
                    def.guidByOldReportId[oldReportId] = table.guid;
                }
            }
        }
    }
    return tablesDefinition;
};

export const getOutputColumns = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
    tableGuid: ReportApp.TableGuid,
) => {
    const table = getTableByGuid(state, oldReportId, tableGuid);
    if (!table) {
        return [];
    }
    return table.outputColumns;
};

export const getHasOutputColumns = (
    state: DefaultRootState,
    tableGuidByOldReportId: ReportApp.TableGuidByOldReportId,
) =>
    Object.keys(tableGuidByOldReportId).some((oldReportId) => {
        const tableGuid = tableGuidByOldReportId[oldReportId];
        if (!tableGuid) {
            return false;
        }
        return getOutputColumns(state, oldReportId, tableGuid).length > 0;
    });

const isSingleYearReport = (state: DefaultRootState) => {
    const definition = getDefinition(state);
    return definition?.geoGroups.every((geoGroup) => {
        return (
            geoGroup.columnGroups.length === 1 &&
            geoGroup.columnGroups[0].tables.length === 1
        );
    });
};

/**
 * We allow transposed excel download for reports which:
 * 1. consist of only 1 table
 * 2. are not multi year
 * 3. do not have output columns
 */
export const getShouldAllowExcelTranspose = (state: DefaultRootState) => {
    const tables = getTablesUsedInReport(state);
    if (tables.length !== 1) {
        return false;
    }

    const table = tables[0];
    if (getHasOutputColumns(state, table.guidByOldReportId)) {
        return false;
    }

    return isSingleYearReport(state);
};

export const getNumberOfVariablesForTables = (
    state: DefaultRootState,
    tableGuidByOldReportId: ReportApp.TableGuidByOldReportId,
) => {
    const oldReportId = Object.keys(tableGuidByOldReportId)[0];
    const tableGuid = tableGuidByOldReportId[oldReportId];
    if (!tableGuid) {
        return 0;
    }
    const table = getTableByGuid(state, oldReportId, tableGuid);
    if (!table) {
        return 0;
    }
    return table.variableCount;
};

export const getVariable = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
    tableGuid: ReportApp.TableGuid,
    variableIndex: number,
) => {
    const table = getTableByGuid(state, oldReportId, tableGuid);
    if (!table) {
        return undefined;
    }

    const selectedDollarAdjustmentYear = Report.getSelectedDollarAdjustment(
        state,
    );
    const variable = Object.values(table.variables)[variableIndex] as Variable;
    // The default label has never <DollarYear> tag inside it. The original
    // label can have this tag. We need to return a transformed label (meaning
    // the dollar tag should be replaced with the selected dollar adjustment
    // year).
    const isCOT = isReportCOT(state);
    return {
        ...variable,
        label: variable.dollarYear
            ? getDollarAdjustedTitle(
                  variable.originalLabel,
                  selectedDollarAdjustmentYear || variable.dollarYear,
                  isCOT,
              )
            : variable.label,
    } as Variable;
};

export const getFirstVariable = (
    state: DefaultRootState,
    tableGuidByReportId: ReportApp.TableGuidByOldReportId,
    variableIndex: number,
) => {
    const oldReportId = Object.keys(tableGuidByReportId)[0];

    const tableGuid = tableGuidByReportId[oldReportId];
    if (!tableGuid) {
        return undefined;
    }
    return getVariable(state, oldReportId, tableGuid, variableIndex);
};
export const getState = (state: DefaultRootState) => state;

export const getIsDefinitionAndByOldReportIdLoaded = (
    state: DefaultRootState,
) =>
    !!getDefinition(state) &&
    Object.keys(state.reportMetadata.byOldReportId).length > 0 &&
    Survey.getAllSurveyGroups(state) != null;

export const getSurveyCodesInReportBySurveyGroup = (
    state: DefaultRootState,
) => {
    const surveyCodesBySurveyGroup: ReportApp.SurveyCodesBySurveyGroupId = {};
    if (!getIsDefinitionAndByOldReportIdLoaded(state)) {
        return surveyCodesBySurveyGroup;
    }

    const oldReportIds = getOldReportIds(state);
    for (const oldReportId of oldReportIds) {
        const { surveyCode } = getTables(state, oldReportId)[0];
        const surveyGroup = Survey.getSurveyGroupBySurveyCode(
            state,
            surveyCode,
        );
        if (!surveyGroup) {
            if (process.env.NODE_ENV === 'development') {
                throw new Error(
                    `Survey group for ${surveyCode} couldn't be found. You probably haven't loaded surveys in your local database or survey ${surveyCode} is not available in tenant: ${process.env.REACT_APP_TENANT}. To load surveys in local database you need to use two oneoff scripts located in 'backend/oneoff_scripts/2020_05_12_16_25_25_update_surveys' that you must run. First run 'ruby import_dataset.rb', then 'ruby enable_only_available_surveys.rb'. Make sure that you have VPN up and running and appropriate hosts file entry set.`,
                );
            }
            throw new Error(`Survey group for ${surveyCode} couldn't be found`);
        }
        let surveyCodes = surveyCodesBySurveyGroup[surveyGroup.id];
        if (!surveyCodes) {
            surveyCodesBySurveyGroup[surveyGroup.id] = surveyCodes = new Set();
        }
        surveyCodes.add(surveyCode);
    }
    return surveyCodesBySurveyGroup;
};

const isReportUDA = (state: DefaultRootState) => {
    const definition = getDefinition(state);
    if (!getIsDefinitionAndByOldReportIdLoaded(state) || !definition) {
        return false;
    }
    const oldReportIds = getOldReportIds(state);
    return (
        oldReportIds.length !== 1 ||
        definition.geoGroups.some((geoGroup) => geoGroup.name)
    );
};

export const isReportCOT = (state: DefaultRootState) => {
    const definition = getDefinition(state);
    if (!getIsDefinitionAndByOldReportIdLoaded(state) || !definition) {
        return false;
    }
    return definition.geoGroups.some(
        (geoGroup) => geoGroup.columnGroups.length > 1,
    );
};

/**
 * See if geo modification is enabled (based on report definition)
 */
export const getShouldEnableGeosModification = (state: DefaultRootState) =>
    !isReportUDA(state);

export const getSurveyGroupsAndModifyTableLink = (state: DefaultRootState) => {
    const surveyCodesBySurveyGroup = getSurveyCodesInReportBySurveyGroup(state);
    return Object.keys(surveyCodesBySurveyGroup).map((surveyGroupId) => ({
        surveyGroup: Survey.getSurveyGroupById(state, +surveyGroupId),
        link: ApplicationPaths.tables.getPath({
            surveyCodes: Array.from(
                surveyCodesBySurveyGroup[+surveyGroupId]!,
            ).join(','),
        }),
    }));
};

export const getTablesConstructedForReport = (state: DefaultRootState) => {
    const definition = getDefinition(state);
    if (definition) {
        // we are editing existing report
        const tablesInReport = getTablesUsedInReport(state);
        const isCOT = isReportCOT(state);
        return tablesInReport
            .map((tableInReport) => {
                const guidBySurveyCode: ReportApp.TableGuidBySurveyCode = {};
                const oldReportIds = Object.keys(
                    tableInReport.guidByOldReportId,
                );
                for (const oldReportId of oldReportIds) {
                    const { surveyCode } = getTables(state, oldReportId)[0];
                    guidBySurveyCode[surveyCode] =
                        tableInReport.guidByOldReportId[oldReportId];
                }
                const oldReportId = oldReportIds[oldReportIds.length - 1];
                const tableGuid = tableInReport.guidByOldReportId[oldReportId];
                if (!tableGuid) {
                    return null;
                }
                const table = getTableByGuid(state, oldReportId, tableGuid);
                if (!table) {
                    return null;
                }
                return constructTableForReport(guidBySurveyCode, table, isCOT);
            })
            .filter(filterFalsy);
    }

    return [];
};

export const getModifyGeographies = (state: DefaultRootState) => {
    const oldReportIds = getOldReportIds(state);
    if (oldReportIds.length !== 1) {
        return [];
    }
    const oldReportId = oldReportIds[0];
    const { surveyCode } = getTables(state, oldReportId)[0];
    return [
        {
            label: intl.formatMessage({
                id: 'report.sidebar.modify.changeGeography',
            }),
            link: ApplicationPaths.geographies.getPath({ surveyCode }),
        },
    ];
};

export const getGeoGroupsWithGeoTypes = (state: DefaultRootState) => {
    const definition = getDefinition(state);
    if (!getIsDefinitionAndByOldReportIdLoaded(state) || !definition) {
        return [];
    }
    return definition.geoGroups.map((geoGroup) => ({
        name: geoGroup.name,
        columnGroups: geoGroup.columnGroups.map((columnGroup) => ({
            name: columnGroup.name,
            tables: columnGroup.tables.map((oldReportId) => {
                return {
                    reportId: oldReportId,
                    geoTypeSelections: getGeoTypeSelections(state, oldReportId),
                };
            }),
        })),
    }));
};

export const getSurveyCodesUsedInReport = (state: DefaultRootState) => {
    const surveyCodesBySurveyGroup = getSurveyCodesInReportBySurveyGroup(state);
    return Object.values(surveyCodesBySurveyGroup)
        .filter(filterFalsy)
        .flatMap((surveyCodes) => Array.from(surveyCodes));
};

export const getNameOfFirstSurveyUsedInReport = (state: DefaultRootState) => {
    const surveyCodesUsedInReport = getSurveyCodesUsedInReport(state);
    return Survey.getSurveyNameByCode(state, surveyCodesUsedInReport[0]);
};

export const getSurveyYearsUsedInReport = (state: DefaultRootState) => {
    const surveyCodesUsedInReport = getSurveyCodesUsedInReport(state);
    return surveyCodesUsedInReport.map(
        (surveyCode) => Survey.getSurveyByCode(state, surveyCode).year,
    );
};

export const getByOldReportId = (state: DefaultRootState) =>
    state.reportMetadata.byOldReportId;

export const getNumberOfTablesInReport = (state: DefaultRootState) => {
    if (!getIsDefinitionAndByOldReportIdLoaded(state)) {
        return 0;
    }
    const { byOldReportId } = state.reportMetadata;
    const allTables: string[] = [];
    Object.keys(byOldReportId).forEach((oldReportId) => {
        const tableIdentifiers = new Set<string>();
        getTableGuids(state, oldReportId).forEach((key) => {
            const table = byOldReportId[oldReportId].tables[key];
            if (!table) {
                return;
            }
            const { variableCount, name } = table;
            // see wall of text in getTablesUsedInReport for explanation of
            // why we do this
            let index = 0;
            while (true) {
                const code = `${variableCount}|${name}|${index}`;
                if (tableIdentifiers.has(code)) {
                    index += 1;
                } else {
                    tableIdentifiers.add(code);
                    break;
                }
            }
        });
        allTables.push(...Array.from(tableIdentifiers));
    });
    return Array.from(new Set(allTables)).length;
};

export const getDatasetsUsedInReportBySurveyCode = (
    state: DefaultRootState,
) => {
    if (!getIsDefinitionAndByOldReportIdLoaded(state)) {
        return {};
    }

    const oldReportIds = getOldReportIds(state);
    const datasetsBySurveyCode: ReportApp.DatasetBySurveyCode = {};
    for (const oldReportId of oldReportIds) {
        const tables = getTables(state, oldReportId);
        for (const { datasetAbbreviation, surveyCode } of tables) {
            let datasets = datasetsBySurveyCode[surveyCode];
            if (!datasets) {
                datasetsBySurveyCode[surveyCode] = datasets = new Set();
            }
            const detailedDataset = Table.getDatasets(state, surveyCode)?.find(
                (dataset) => dataset.abbreviation === datasetAbbreviation,
            );
            if (detailedDataset) {
                datasets.add(detailedDataset);
            }
        }
    }
    return datasetsBySurveyCode;
};

/**
 * Checks whether any dataset is repeated more than once
 */
export const getHasMultipleDatasets = (state: DefaultRootState) => {
    if (!getIsDefinitionAndByOldReportIdLoaded(state)) {
        return false;
    }
    const oldReportIds = getOldReportIds(state);

    const usedDatasets = new Set();
    for (const oldReportId of oldReportIds) {
        const tables = getTables(state, oldReportId);
        for (const { datasetAbbreviation } of tables) {
            usedDatasets.add(datasetAbbreviation);
            if (usedDatasets.size > 1) {
                return true;
            }
        }
    }
    return false;
};

const getIndexOfFipsCodeInOldReport = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
    fipsCode: ReportApp.FipsCodeWithSummaryLevel | null,
) => {
    if (!fipsCode) {
        return -1;
    }
    const fipsCodesOfOldReportId = getReportFipsCodes(state, oldReportId);
    if (!fipsCodesOfOldReportId) {
        return -1;
    }
    return fipsCodesOfOldReportId.indexOf(fipsCode);
};

export const getReportDataSegmentsByGeoGroups = (
    state: DefaultRootState,
    geoItemPage: number,
    geoItemsPerPage: number,
    tablePage: number,
    tablesPerPage: number,
): ReportApp.ReportSegments[] => {
    const definition = getDefinition(state);
    if (!getIsDefinitionAndByOldReportIdLoaded(state) || !definition) {
        return [];
    }
    const { geoGroups } = definition;
    const selectedAggregation = Report.getAggregationType(state);
    const shouldAllowAggregationSelection = getShouldAllowAggregation(state);

    const lastTableIndex = tablePage * tablesPerPage;
    const firstTableIndex = lastTableIndex - tablesPerPage;
    const tablesOnCurrentPage = getTablesUsedInReport(state).slice(
        firstTableIndex,
        lastTableIndex,
    );

    const segments: { [oldReportId: string]: ReportApp.SegmentGeoInfo } = {};

    const lastGeoElementIndex = geoItemPage * geoItemsPerPage;
    const firstGeoElementIndex = lastGeoElementIndex - geoItemsPerPage;

    const geosInReport = getGeographies(state).slice(
        firstGeoElementIndex,
        lastGeoElementIndex,
    );
    for (const geo of geosInReport) {
        const { columnGroups, aggregationType } = geoGroups[geo.geoGroupIndex];
        for (const { tables } of columnGroups) {
            for (const oldReportId of tables) {
                const fipsIndex =
                    getIndexOfFipsCodeInOldReport(
                        state,
                        oldReportId,
                        geo.geoFips,
                    ) + 1; // backend works with 1 based index, not 0 based index
                if (fipsIndex !== 0) {
                    if (!segments[oldReportId]) {
                        segments[oldReportId] = {
                            startIndex: fipsIndex,
                            endIndex: fipsIndex,
                            aggregationType: shouldAllowAggregationSelection
                                ? selectedAggregation
                                : aggregationType,
                        };
                    }
                    segments[oldReportId].startIndex = Math.min(
                        segments[oldReportId].startIndex,
                        fipsIndex,
                    );
                    segments[oldReportId].endIndex = Math.max(
                        segments[oldReportId].endIndex,
                        fipsIndex,
                    );
                }
            }
        }
    }
    return Object.keys(segments)
        .map((oldReportId: ReportApp.OldReportId) => {
            const { aggregationType, startIndex, endIndex } = segments[
                oldReportId
            ];
            return {
                startIndex,
                geoItemsRange: endIndex - startIndex + 1,
                oldReportId,
                aggregationType,
                tableGuids: tablesOnCurrentPage
                    .map(
                        (tableOnCurrentPage) =>
                            tableOnCurrentPage.guidByOldReportId[oldReportId],
                    )
                    .filter(filterFalsy),
            };
        })
        .filter((segment) => segment.tableGuids.length > 0);
};

const getTotalNumberOfGeosByGeoGroup = (state: DefaultRootState): number[] => {
    const definition = getDefinition(state);
    if (!getIsDefinitionAndByOldReportIdLoaded(state) || !definition) {
        return [];
    }

    const geoGroups = definition.geoGroups;
    return geoGroups.map((geoGroup) => {
        return Math.max(
            ...geoGroup.columnGroups.map((columnGroup) => {
                return Math.max(
                    ...columnGroup.tables.map(
                        (oldReportId) =>
                            getTotalNumberOfGeographiesByOldReportId(
                                state,
                                oldReportId,
                            ) ?? 0,
                    ),
                );
            }),
        );
    });
};

export const getTotalNumberOfGeographies = (state: DefaultRootState) =>
    getTotalNumberOfGeosByGeoGroup(state).reduce((a, b) => a + b, 0);

/**
 * Gets number of report columns. This will not take into account first
 * column, where variable labels are.
 */
export const getNumberOfValueColumnsInReport = (state: DefaultRootState) => {
    const geos = getPaginatedGeographies(state);
    return geos.reduce((memo, geo) => geo.colSpan + memo, 0);
};

/**
 * Retrieve number of selected columns per geography
 */
export const getNumberOfSelectedColumns = (state: DefaultRootState): number => {
    const geographies = getPaginatedGeographies(state);
    if (!geographies.length) {
        return 0;
    }
    return getTotalNumberOfGeographies(state) * geographies[0].colSpan;
};

export const getYearsUsedInReportBySurveyGroupId = (
    state: DefaultRootState,
) => {
    const surveyCodesInReportBySurveyGroupId = getSurveyCodesInReportBySurveyGroup(
        state,
    );

    const yearsBySurveyGroupId: ReportApp.YearsBySurveyGroupId = {};

    return Object.keys(surveyCodesInReportBySurveyGroupId).reduce(
        (memo, surveyGroupIdKey) => {
            const surveyGroupId = +surveyGroupIdKey;
            const surveyGroup = Survey.getSurveyGroupById(state, surveyGroupId);
            const usedSurveyCodes =
                surveyCodesInReportBySurveyGroupId[surveyGroupId];
            if (!usedSurveyCodes || usedSurveyCodes.size === 0) {
                return memo;
            }
            const selectedSurveys = Array.from(
                usedSurveyCodes,
            ).map((surveyCode) => Survey.getSurveyByCode(state, surveyCode));
            const { comparabilityGroup } = selectedSurveys[0];
            const selectedYears = selectedSurveys
                .map((survey) => survey.year)
                .sort();
            const years = surveyGroup.surveyCodes
                .map((surveyCode: string) =>
                    Survey.getSurveyByCode(state, surveyCode),
                )
                .filter(
                    (survey: ReportApp.Survey) =>
                        survey.comparabilityGroup &&
                        survey.comparabilityGroup === comparabilityGroup,
                )
                .map((survey: ReportApp.Survey) => survey.year)
                .filter((year: number) => year)
                .sort();
            memo[surveyGroupId] = {
                name: surveyGroup.name,
                years,
                selectedYears,
                comparabilityGroup,
            };
            return memo;
        },
        yearsBySurveyGroupId,
    );
};

/**
 * This function is written under presumption that oldReportId can not appear twice
 * across different geoGroups
 */
export const getGeoGroupOfOldReportId = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
) => {
    const geoGroups = getDefinition(state)?.geoGroups || [];
    const resultingGeoGroup = geoGroups.find(({ columnGroups }) => {
        return columnGroups.find(({ tables }) => {
            return tables.find((table: string) => table === oldReportId);
        });
    });

    return resultingGeoGroup;
};

function getGroupColSpanAndGeoFipsCodes(
    state: DefaultRootState,
    tables: string[],
    aggregationType: number,
) {
    let fipsCodesWithSumLev: string[] = [];

    let groupColSpan = 0;
    for (const oldReportId of tables) {
        groupColSpan = Math.max(
            groupColSpan,
            getMaxNumberOfColumnsForOldReport(state, oldReportId),
        );
        const fipsCodesOfReport = getReportFipsCodes(state, oldReportId) ?? [];

        fipsCodesWithSumLev =
            aggregationType === AggregationTypes.ALL_SLS_TOTAL
                ? [ALL_SLS_TOTAL_COLUMN_NAME]
                : fipsCodesWithSumLev.concat(fipsCodesOfReport);
    }

    return {
        groupColSpan,
        fipsCodesWithSumLev: fipsCodesWithSumLev,
    };
}

export const getTableAndVariableByOldReportIdDatasetVariableCode = (
    state: DefaultRootState,
    oldReportId: ReportApp.OldReportId,
    datasetCode: string,
    variableCode: string,
) => {
    for (const table of Object.values(
        state.reportMetadata.byOldReportId[oldReportId].tables,
    )) {
        if (table?.datasetAbbreviation === datasetCode) {
            const variable = Object.values(table.variables).find(
                (variable) => variableCode === variable?.name,
            );
            if (variable) {
                return { table, variable };
            }
        }
    }
    return { table: null, variable: null };
};

export const getShouldAllowExcelDownload = (state: DefaultRootState) => {
    const oldReportId = getSingleReportId(state);
    const totalNumberOfGeographies =
        getTotalNumberOfGeographiesByOldReportId(state, oldReportId) || 0;
    return (
        Table.getAreDatasetsLoaded(state) &&
        totalNumberOfGeographies <= MAX_GEOGRAPHIES_ALLOWED_FOR_DOWNLOAD
    );
};

export const getCitationTableDataInReport = (state: DefaultRootState) => {
    if (!getIsDefinitionAndByOldReportIdLoaded(state)) {
        return [];
    }
    const citationData: ReportApp.CitationTableData[] = [];

    getSurveyCodesUsedInReport(state).forEach((surveyCode) => {
        const survey = Survey.getSurveyByCode(state, surveyCode);
        const tables = getTablesConstructedForReport(state);
        const datasets = Table.getDatasets(state, surveyCode) || [];
        datasets.forEach((dataset) => {
            const tablesByDataset = tables.filter((table) =>
                dataset.tableGuids.some((guid) =>
                    table.composeGuid
                        .split('|')
                        .some((cguid) => cguid === guid),
                ),
            );
            tablesByDataset.forEach((table) => {
                citationData.push({
                    surveyCode: survey.code,
                    surveyName: survey.name,
                    year: survey.year,
                    publisher: dataset.publisher,
                    tableName: table.title,
                    link: BackendPaths.metadataLink.getPath({
                        surveyCode,
                        datasetAbbreviation: dataset.abbreviation,
                        name: table.name,
                    }),
                });
            });
        });
    });
    return citationData;
};

export const getSurveyOrGroupName = (state: DefaultRootState) => {
    const surveyCodesBySurveyGroup = getSurveyCodesInReportBySurveyGroup(state);
    const surveyGroupIds = Object.keys(surveyCodesBySurveyGroup).map(Number);
    if (surveyGroupIds.length === 0) {
        return null;
    }
    const surveyGroupNames = surveyGroupIds
        .map((groupId) => Survey.getSurveyGroupById(state, groupId))
        .map((surveyGroup) => surveyGroup.name)
        .join(', ');
    const surveyCodes = surveyCodesBySurveyGroup[surveyGroupIds[0]];
    if (!surveyCodes) {
        throw new Error(
            `There is no survey group with id ${surveyGroupIds[0]}.`,
        );
    }
    if (surveyGroupIds.length > 1 || surveyCodes.size > 1) {
        return surveyGroupNames;
    }
    const surveyCode = surveyCodes.values().next().value;
    return Survey.getSurveyByCode(state, surveyCode).name;
};

export const preventCSVDownload = (state: DefaultRootState) => {
    const surveyCodes = getSurveyCodesUsedInReport(state);
    return blackListedSurveysForCSVDownload.some((surveyCode) =>
        surveyCodes.some((sl) => sl === surveyCode),
    );
};
